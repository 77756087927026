'use client';

import Link from 'next/link';
import * as React from 'react';

interface LinkWrapperProps {
  to?: string;
  children?: React.ReactNode;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  href?: string;
  [key: string]: any;
}

const LinkWrapper = React.forwardRef<HTMLAnchorElement, LinkWrapperProps>(function LinkWrapperComponent(
  { to, href, children, ...props },
  ref,
) {
  const destination = to || href || '/';

  return (
    <Link
      href={destination}
      ref={ref}
      {...props}
    >
      {children}
    </Link>
  );
});

LinkWrapper.displayName = 'LinkWrapper';

export default LinkWrapper;
