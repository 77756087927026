export enum SearchTypes {
    none,
    obsolete,
    keyword,
    language,
    concepts,
    vocabulary,
    affixes,
    allCombinations,
    allAffixCombinations,
    twoWord,
    oneWord,
    threeWord,
    wordplay,
    random,
    categories,
    append,
    exactAndVariations,
    reduplicated,
    spoonerisms,
    misspellings,
    randomCompounds,
    closestSynonyms,
    basicAlterntives,
    taken,
    dictionary,
    madeup,
    compounds,
    translations,
    allExtensions,
    quick,
    bulk,
    favorites,
    handPicked,
    ai,
    portmanteaus,
    recheck,
    elaborate,
    contestResponse,
    nameTemplates,
    alliterations,
    forSale,
    joinTLDs,
    derivations,
    tweaked,
    singleDomain,
    popular,
    business,
    corporate,
    suggestive,
    listSuffixes,
}
