export enum OptionTypes {
    none,
    nameType,
    focus,
    theme,
    options,
    extension,
    language,
    searchMethod,
}
