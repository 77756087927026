const cdnUrls = ['static-img.aripaev.ee', 'static-img.aripaev.info'];
const directusProxyUrls = ['cms-directus-ui.ap3.ee', 'directus-improxy.ap3.ee', 'directus-dev.aripaev.info'];
const localPaths = ['/tmp/', '/supporters/', '/logos/'];

const removeProtocol = (src) => src.replace('https://', '').replace('http://', '');

const isCdnSrc = (src) => cdnUrls.some((url) => removeProtocol(src).startsWith(url));
const isDirectus = (src) => directusProxyUrls.some((url) => removeProtocol(src).startsWith(url));
const isLocal = (src) => localPaths.some((path) => src.startsWith(path));

export default function imageLoader({ src, width, quality = 75 }) {
  if (isCdnSrc(src)) {
    return `${src}&width=${width}&q=${quality}`;
  }
  if (isDirectus(src)) {
    return `${src}&w=${width}&q=${quality}`;
  }
  if (isLocal(src)) {
    // Avoid width usage warnings from next
    return `${src}?w=${width}`;
  }
  return src;
}
