'use client';
import * as React from 'react';
import { includes } from 'lodash';
import LayoutOverlay from 'namestation-ui/lib/Layouts/Overlay';
import Block from 'namestation-ui/lib/Components/Block';
import Overlay from 'namestation-ui/lib/Components/Overlay';
import { Overlays } from 'namestation-ui/lib/Providers/Overlays';
import router from 'next/router';
import Buttons from 'namestation-ui/lib/Components/Buttons';
import Button from 'namestation-ui/lib/Components/Button';
import ScreenshotMain from '@/components/Screenshots';
import LinkWrapper from '@/components/Layout/link-wrapper';
import FeaturesList from '@/components/FeaturesList';
import { Suspense } from 'react';
import { urls } from 'data/urls';

export interface IOverlaySignupRequiredProps {
  layout?: 'default';
}

const OverlayKeywords: React.FunctionComponent<IOverlaySignupRequiredProps> = (props) => {
  const { openOverlays, overlaysActions } = React.useContext(Overlays);

  return (
    <Overlay
      isOpen={includes(openOverlays, 'signup-required')}
      layout="focusview"
      title="Pick the Perfect Name"
      doClose={() => {
        overlaysActions({ action: 'close', name: 'all' });
        //  router.back();
      }}
      disableScrolling={true}
    >
      <LayoutOverlay
        title="Pick the Perfect Name"
        description="Try our advanced tools today for names you won’t find anywhere else."
        size="medium"
        buttonIcons={[
          {
            icon: 'close',
            onClick: () => {
              overlaysActions({ action: 'close', name: 'all' });
              //  router.back();
            },
            layout: 'icon-only',
          },
        ]}
        scrollableContent={true}
      >
        <React.Fragment>
          <hr className="mobile-hide" />
          <Block>
            <ul>
              <li>AI-powered name generators</li>
              <li>One-word names, wordplay names, dictionary names, and more</li>
              <li>Search by keywords, topics, or creative briefs</li>
              <li>Instant domain check</li>
              <li>Advanced filtering and sorting</li>
              <li>Crowdsourced name contests</li>
            </ul>
            {/*  <FeaturesList type="shortest" />
            <p></p> */}
            <Buttons layout="vertical">
              <Button
                title="Start Creating Custom Names"
                layout="default"
                intent="primary"
                link={LinkWrapper}
                href={urls.signup}
                type="anchor"
                onClick={() => {
                  overlaysActions({ action: 'close', name: 'all' });
                }}
              />
              <Button
                title="Hold a Name Contest  "
                layout="link"
                intent="default"
                type="anchor"
                link={LinkWrapper}
                href={urls.contests}
                onClick={() => {
                  overlaysActions({ action: 'close', name: 'all' });
                }}
              />
            </Buttons>
          </Block>
        </React.Fragment>
      </LayoutOverlay>
    </Overlay>
  );
};

OverlayKeywords.displayName = 'OverlayKeywords';

export default OverlayKeywords;
