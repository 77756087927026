export interface Urls {
  signup: string;
  login: string;
  startContest: string;
  contests: string;
  domainNameGenerator: string;
  domainSearch: string;
  domainSearchAI: string;
  signUpForContest: string;
  plans: string;
  becomeContributor: string;
  about: string;
  pricing: string;
  earn: string;
  home: string;
  facebook: string;
  twitter: string;

  youtube: string;
  names: string;

  languages: string;
  extensions: string;
  registrars: string;
  terms: string;
  privacy: string;
  newContest: string;
  blog: string;
  goDaddy: string;
  listYourAgency: string;
  randomNames: string;
  signupUrlApp: string;
  homeInternal: string;
}

const appUrl = process.env.NEXT_PUBLIC_APP_URL;
const signupUrl = '/signup';
const signupUrlApp = appUrl + '/signup';

export const urls: Urls = {
  home: '/',
  homeInternal: appUrl + '/home',
  contests: '/contests',
  domainNameGenerator: '/domain-name-generator',
  domainSearch: '/domain-search',
  domainSearchAI: '/domain-search/ai',
  plans: '/pricing',
  becomeContributor: '/become-a-contributor',
  about: '/about',
  pricing: '/pricing',
  earn: '/earn',
  terms: '/terms',
  privacy: '/privacy',
  names: '/names',

  languages: '/languages',
  extensions: '/extensions',
  registrars: '/registrars',
  listYourAgency: '/agency',
  randomNames: '/names',

  //APP
  signupUrlApp: signupUrlApp,
  signup: signupUrl,
  login: appUrl + '/login  ',
  startContest: signupUrlApp + '?contest=true',
  signUpForContest: signupUrlApp + '?contest=true',
  newContest: signupUrlApp + '?contest=true',

  //EXTERNAL
  facebook: 'https://www.facebook.com/namestation',
  twitter: 'https://x.com/namestation',
  blog: 'https://blog.namestation.com/',
  youtube: 'https://www.youtube.com/@namestation',
  goDaddy: 'https://www.anrdoezrs.net/click-5299569-15819874',
};
