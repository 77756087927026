'use client';
import * as React from 'react';
import { includes } from 'lodash';
import LayoutOverlay from 'namestation-ui/lib/Layouts/Overlay';
import Block from 'namestation-ui/lib/Components/Block';
import Overlay from 'namestation-ui/lib/Components/Overlay';
import { Overlays } from 'namestation-ui/lib/Providers/Overlays';
import router from 'next/router';

export interface IOverlaySignupRequiredProps {
  layout?: 'default';
}

const OverlayExtensions: React.FunctionComponent<IOverlaySignupRequiredProps> = (props) => {
  const { openOverlays, overlaysActions } = React.useContext(Overlays);

  return (
    <Overlay
      isOpen={includes(openOverlays, 'extensions')}
      layout="focusview"
      title="Preferred Extensions"
      doClose={() => {
        overlaysActions({ action: 'close', name: 'all' });
        //  router.back();
      }}
      disableScrolling={true}
    >
      <LayoutOverlay
        title="Preferred Extensions"
        description="Enter a list of extensions you prefer to be included in the generated names."
        size="medium"
        buttonIcons={[
          {
            icon: 'close',
            onClick: () => {
              overlaysActions({ action: 'close', name: 'all' });
              // router.back();
            },
            layout: 'icon-only',
          },
        ]}
        scrollableContent={true}
      >
        <React.Fragment>
          <Block theme="light">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
            enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
            in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident,
            sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste natus error sit voluptatem
            accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae
            vitae dicta sunt explicabo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore
            et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
            occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis
            iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore
            veritatis et quasi architecto beatae vitae dicta sunt explicabo. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
            nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
            nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
          </Block>
        </React.Fragment>
      </LayoutOverlay>
    </Overlay>
  );
};

OverlayExtensions.displayName = 'OverlayExtensions';

export default OverlayExtensions;
