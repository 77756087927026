'use client';

import * as React from 'react';

import Tabs from 'namestation-ui/lib/Components/Tabs';
import TabsTab from 'namestation-ui/lib/Components/TabsTab';

import { uniq } from 'lodash';

import { SearchTypes } from 'data/models/Enums/searchTypes';

import Tags from 'namestation-ui/lib/Components/Tags';

import { useDebounce } from 'common/functions';

import agent from 'src/api/agent';
import { settings } from 'data/settings';
import { getSlug } from 'common/customFunctions';
import { useRouter } from 'next/navigation';
import LinkWrapper from '@/components/Layout/link-wrapper';
import { Suspense } from 'react';

export interface ITopicSuggestionsProps {
  searchType?: SearchTypes;
  onChange?: (val: string) => void;
  topic?: string;
  count?: number;
  hideMore?: boolean;
  tryLabel?: string;
  initialContent?: string[];
  examples?: 'external' | 'default';
  layout?: 'default' | 'unlimited' | 'tags';
}

const TopicSuggestions: React.FunctionComponent<ITopicSuggestionsProps> = (props) => {
  const [phrase, setPhrase] = React.useState<string>(props.topic || '');

  const [topics, setTopics] = React.useState<string[]>(props.initialContent || []);
  const [isLoadingTopics, setIsLoadingTopics] = React.useState<boolean>(false);
  const router = useRouter();

  const loadTopics = React.useCallback(() => {
    if (!phrase || phrase === '') {
      return;
    }

    if (isLoadingTopics) return;

    setIsLoadingTopics(true);
    agent.Data.topicSuggestions(phrase)
      .then((response) => {
        if (response.categories.length > 0) {
          setTopics(response.categories);
        }
        setIsLoadingTopics(false);
      })
      .catch(() => {
        setIsLoadingTopics(false);
      });
  }, [phrase, isLoadingTopics]);

  const debouncedSearch = useDebounce(loadTopics, 1500);

  const searchWithRouter = (phrase: string) => {
    const slug = getSlug(phrase);
    router.push(`/names/${slug}`);
  };

  React.useEffect(() => {
    if (props.topic) {
      setPhrase(props.topic);
      agent.Data.topicSuggestions(props.topic)
        .then((response) => {
          if (response.categories && response.categories.length > 0) {
            setTopics(response.categories);
          }
        })
        .catch(console.error);
    }
  }, [props.topic]);

  React.useEffect(() => {
    if (phrase) {
      debouncedSearch();
    }
  }, [phrase, debouncedSearch]);

  React.useEffect(() => {
    if (props.initialContent && props.initialContent.length > 0) {
      setTopics(props.initialContent);
    }
  }, [props.initialContent]);

  if (topics?.length === 0) return null;

  return (
    <React.Fragment>
      <ul className="tabs layout-tags wrapped separatedtags">
        {topics.slice(0, settings.topicSuggestionsLimit).map((topic) => (
          <li
            className="tabs-tab"
            key={topic}
          >
            <LinkWrapper
              href={`/names/${getSlug(topic)}`}
              className=""
            >
              <span className="label">{topic.toLowerCase()}</span>
            </LinkWrapper>
          </li>
        ))}
      </ul>
    </React.Fragment>
  );
};

TopicSuggestions.displayName = 'TopicSuggestions';

export default TopicSuggestions;
