import { debounce, isEqual, isObject, random, transform, uniq, uniqueId } from 'lodash';
import * as React from 'react';

import { IOverlaysDispatchAction } from 'namestation-ui/lib/Providers/Overlays';

import { trackError } from './customFunctions';

// Returns RGBA value from 6 character length hex string.

export function hexToRGB(hex: string, alpha: number) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')';
}

// Comparison

export function objectDiff(baseObject: any, compareWith: any) {
  function changes(object: any, base: any) {
    return transform(object, (result: any, value, key) => {
      if (!isEqual(value, base[key])) {
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }

  return changes(baseObject, compareWith);
}

// Make first character uppercase

export function toSentenceCase(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Capitalizes first letters of words in string.
 * @param {string} str String to be modified
 * @param {boolean=false} lower Whether all other letters should be lowercased
 * @return {string}
 * @usage
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
 */

export function capitalize(str: string, lower = false) {
  return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
}

// Validate e-mail address syntax

export function validateEmail(email: string) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// Currency display

// Returns formatted number

export function formattedNumber(n: number, displayPlus: boolean, fractions?: number) {
  let ret = '';
  if (n > 0 && displayPlus) {
    ret = ret + '+';
  }
  ret = ret + n.toFixed(fractions || 0);
  return ret;
}

// Modal closing handler

export function handleOverlayClosing(
  hash1: string,
  hash2: string,
  overlays: string[],
  closeOverlay: React.Dispatch<IOverlaysDispatchAction>,
) {
  if (hash1 !== hash2) {
    const hash = hash1.substr(1);
    const prevHash = hash2.substr(1);
    if (hash && hash !== '') {
      if (overlays.length > 1 && hash && prevHash) {
        const lastOverlay = overlays[overlays.length - 1];
        if (hash !== lastOverlay) {
          closeOverlay({ action: 'close', name: lastOverlay });
        }
      }
    } else if (overlays.length > 0) {
      closeOverlay({ action: 'close', name: 'all' });
    }
  }
}

// Generates dummy words

/* export function generateRandomWords(min: number, max: number) {
  return uniq(
    Array(random(min, max, false))
      .fill(0)
      .map(() => {
        return loremIpsum({
          count: 1,
          units: 'words',
        });
      })
  );
} */

// Handle error

export function handleError(error: string | Error | undefined) {
  if (!error) return;
  console.log('function handleError');

  // Check if window is defined (client-side) before accessing it
  const currentPath = typeof window !== 'undefined' ? window.location.pathname : 'server-side';

  trackError(
    typeof error === 'string' ? error : 'generic error',
    currentPath,
    typeof error === 'string' ? error : error instanceof Error ? error.message : 'unknown error',
  );

  if (error instanceof Error) {
    // Handle Error object
    console.error('Error message:', error.message);
    console.error('Error stack:', error.stack);
  } else if (typeof error === 'object' && error !== null) {
    // Handle error object with response property
    const errorObj = error as any;
    if (errorObj.response) {
      console.error('Response data:', JSON.stringify(errorObj.response.data));
      console.error('Response status:', JSON.stringify(errorObj.response.status));
      console.error('Response headers:', JSON.stringify(errorObj.response.headers));
    } else if (errorObj.request) {
      // The request was made but no response was received
      console.error('Request:', JSON.stringify(errorObj.request));
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error:', errorObj.message);
    }
  } else if (typeof error === 'string') {
    // Handle string error
    console.error('Error:', error);
  } else {
    // Handle any other type of error
    console.error('Unhandled error:', JSON.stringify(error));
  }
}

// Diffing

export const usePrevious = <T extends unknown>(value: T): T | undefined => {
  const ref = React.useRef<T>();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

// Debouncing

export const useDebounce = (cb: any, delay: number) => {
  const options = {
    leading: false,
    trailing: true,
  };
  const inputsRef = React.useRef(cb);
  const isMounted = useIsMounted();
  React.useEffect(() => {
    inputsRef.current = { cb, delay };
  });

  return React.useCallback(
    debounce(
      (...args) => {
        if (inputsRef.current.delay === delay && isMounted()) inputsRef.current.cb(...args);
      },
      delay,
      options,
    ),
    [delay, debounce],
  );
};

// Mounting hook

export const useIsMounted = () => {
  const isMountedRef = React.useRef(true);
  React.useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  return () => isMountedRef.current;
};

// Async hook

const useAsync = <T,>(fn: () => Promise<T>, deps: any[]) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<Error | undefined>();
  const [res, setRes] = React.useState<T | undefined>();
  React.useEffect(() => {
    setLoading(true);
    let cancel = false;
    fn().then(
      (r) => {
        if (cancel) return;
        setLoading(false);
        setRes(r);
      },
      (e) => {
        if (cancel) return;
        setLoading(false);
        setError(e);
      },
    );
    return () => {
      cancel = true;
    };
  }, deps);
  return { loading, error, res };
};
