export interface Settings {
  siteName: string;
  siteUrl: string;
  siteLogo: string;
  ogImage: string;
  keywordSuggestionsLimit: number;
  wordlistSuggestionsLimit: number;
  topicSuggestionsLimit: number;
  languageSuggestionsLimit: number;
  defaultTitle: string;
  defaultTagline: string;
  defaultCTAExternal: string;
  defaultExample: string;
  defaultPlaceholderTopic: string;
  defaultDescription: string;
  defaultMetaTitle: string;
  defaultMetaTitleAppend: string;
  defaultMetaDescriptionAppend: string;
  defaultQuotaLimit: number;
  googleSiteVerification?: string;
  exampleTopics: string[];
  defaultNamesCount: number;
  googleAnalyticsId: string;
}

const universalLimit = 10;
const root = 'https://www.namestation.com';
import ogImage from '@/assets/img/logo/open-graph-1500x1500.png';
import logo from '@/assets/img/logo/logo.png'; /* 192px */

export const settings: Settings = {
  siteName: 'NameStation Domain Name Generator',
  siteUrl: root,
  siteLogo: root + logo.src,
  ogImage: root + ogImage.src,
  keywordSuggestionsLimit: universalLimit,
  wordlistSuggestionsLimit: universalLimit,
  topicSuggestionsLimit: 6,
  languageSuggestionsLimit: universalLimit,
  //  defaultTitle: 'Find your perfect domain name',
  defaultTitle: 'Pick the Perfect   Name',
  defaultTagline: 'Create precisely targeted available domain  names',
  defaultCTAExternal: 'Start your domain search',
  defaultExample: 'E.g: bakery specializing in birthday cakes',
  defaultPlaceholderTopic: 'E.g: bakery specializing in birthday cakes',

  defaultMetaTitle: 'Intelligent Domain Name Generator & Search | Available Domain Names - NameStation',
  defaultDescription:
    'Discover the perfect domain with NameStation’s AI-powered name generator. Our intelligent search tools offer instant availability checks, tailored keyword suggestions, and crowdsourced naming ideas to help brands stand out online.',
  defaultMetaTitleAppend: 'Domain Name Generator & Search | Available Domains - NameStation',
  defaultMetaDescriptionAppend: 'Discover the perfect domain with NameStation’s AI-powered name generator. ',
  /*     defaultMetaTitle: 'Domain Name Generators, Available Domain Names and Domain Search',
    defaultDescription:
      'Pick the perfect domain name for your brand. Intelligent domain search with AI, instant availability check, crowdsourced name contests, keyword suggestions.', */
  /*    defaultDescription:
      'Find the perfect domain for your brand with our AI-powered domain generator. Get instant availability checks, keyword recommendations, and crowdsource ideas with our powerful domain search tools.', */
  defaultQuotaLimit: 10,
  defaultNamesCount: 30,
  googleAnalyticsId: 'G-W331J9J02P',
  exampleTopics: [
    'tech gadgets',
    'digital marketing',
    'remote work',
    'fitness app',
    'fashion blog',
    'food delivery',
    'french bakery',
    'pet care',
    'brooklyn restaurant',

    'creative agency',
    'ai consulting',
    'fitness app',

    'luxury goods',

    'financial services',
    'crypto',
    'food truck',
    'event planning',
    'home decor',
    'personal coaching',
    'sustainable fashion',
    'gourmet foods',
    'specialty coffee',
    'beauty salon',

    'travel agency',

    'educational toys',
    'tech gadget',

    'craft brewery',
    'yoga studio',
    'handmade jewelry',
    'adventure tours',
    'smart home',
    'interior design',
    'organic foods',
    'music lessons',
    'sports equipment',
    'outdoor gear',
    'bookstore',
    'cybersecurity',

    'boutique hotel',
    'wine tasting',
    'renewable energy',
    'data analytics',
    'social media consulting',
    'drone photography',
    'cultural experiences',
    'specialty teas shop',
    'organic skincare',
    'mental health services',
    'vintage clothing',
    'plant nursery',
    'wedding services',
  ],
};
