import { PopularityLevels } from 'data/models/Enums/popularityLevels';
import { WordTypes } from 'data/models/Enums/wordTypes';
import { IDictionaryQueryDTO } from 'data/models/iDictionaryQueryDTO';
import { INicheDTO } from 'data/models/iNicheDTO';
import { IProjectDTO } from 'data/models/iProjectDTO';
import { toSentenceCase } from './functions';
import { toUpper, uniq } from 'lodash';
import ReactGA from 'react-ga4';
import { IOnboardingProjectDTO } from 'data/models/iOnboardingProjectDTO';

export const getSlug = (phrase: string) => {
  return phrase
    .toLowerCase()
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9-]/g, '');
};

export const hasAnyWords = (project: IProjectDTO) => {
  return project.niche.keywords.length != 0 || project.niche.prefixes.length != 0 || project.niche.suffixes.length != 0;
};

export const getMinPopularity = (level: PopularityLevels) => {
  let response = 0;

  if (level === PopularityLevels.high) response = 2500;
  if (level === PopularityLevels.medium) response = 1500;
  if (level === PopularityLevels.low) response = 300;

  return response;
};

export const updateNicheStats = (niche: INicheDTO) => {
  // return niche;
  let keywordCountLabel = '0 words';
  let wordlistCountLabel = '0 wordlists';

  niche.stats.keywords = niche.keywords.length;
  niche.stats.prefixes = niche.prefixes.length;
  niche.stats.suffixes = niche.suffixes.length;
  niche.stats.wordlists = niche.wordlistLabels.length;
  // console.log('niche.wordlistLabels.length', niche.wordlistLabels.length);

  niche.stats.allWordsTotal = niche.stats.keywords + niche.stats.prefixes + niche.stats.suffixes;

  // only display the final (calculated) counts
  niche.stats.allWordsCountFinal = /* niche.includeTeamKeywords ? niche.stats.allWordsTotalByAllUsers : */ niche.stats.allWordsTotal;

  niche.stats.keywordCountFinal = /* niche.includeTeamKeywords ? niche.stats.keywordsTotalByAllUsers : */ niche.stats.keywords;
  niche.stats.prefixCountFinal = /* niche.includeTeamKeywords ? niche.stats.prefixesTotalByAllUsers : */ niche.stats.prefixes;
  niche.stats.suffixCountFinal = /* niche.includeTeamKeywords ? niche.stats.suffixesTotalByAllUsers : */ niche.stats.suffixes;
  niche.stats.wordlistCountFinal =
    /* niche.includeTeamKeywords ? niche.stats.wordlistsTotalByAllUsers + niche.stats.wordlists : */ niche.stats.wordlists;

  keywordCountLabel = niche.stats.allWordsCountFinal + ' keyword' + (niche.stats.allWordsCountFinal === 1 ? '' : 's');

  // final label to display
  /*  if (activeSearch) {
    if (activeSearch.options.includeMyKeywords && activeSearch.options.includeTeamKeywords && niche.stats.keywordsTotalByTeam > 0) {
      /*  keywordCountLabel = keywordCountLabel + ', ' + niche.stats.keywordsTotalByTeam + ' by team'; */
  /*  } else if (activeSearch.options.includeMyKeywords) {
      keywordCountLabel = niche.stats.allWordsCountFinal + ' word' + (niche.stats.allWordsCountFinal === 1 ? '' : 's');
    } else if (activeSearch.options.includeTeamKeywords) {
      keywordCountLabel = niche.stats.keywordsTotalByTeam + ' words';
    } else {
      if (niche.stats.allWordsCountFinal > 0) {
        keywordCountLabel = keywordCountLabel + ' (not included)';
      }
    } */
  /*  } else { */
  keywordCountLabel = niche.stats.allWordsCountFinal + ' word' + (niche.stats.allWordsCountFinal === 1 ? '' : 's');
  /*   } */
  if (niche.stats.wordlistCountFinal > 0)
    wordlistCountLabel = niche.stats.wordlistCountFinal + (niche.stats.wordlistCountFinal === 1 ? ' wordlist' : ' wordlists');

  niche.stats.keywordCountLabel = keywordCountLabel;
  niche.stats.wordlistCountLabel = wordlistCountLabel;

  // my data only
  niche.stats.keywordsLabel = niche.stats.keywords + ' keyword' + (niche.stats.keywords === 1 ? '' : 's');
  niche.stats.prefixesLabel = niche.stats.prefixes + ' prefix' + (niche.stats.prefixes === 1 ? '' : 'es');
  niche.stats.suffixesLabel = niche.stats.suffixes + ' suffix' + (niche.stats.suffixes === 1 ? '' : 'es');
  niche.stats.keywordsTotalByTeamLabel = niche.stats.keywordsTotalByTeam + ' keyword' + (niche.stats.keywordsTotalByTeam === 1 ? '' : 's');
  niche.stats.wordlistsLabel = niche.stats.wordlists + ' wordlist' + (niche.stats.wordlists === 1 ? '' : 's');

  // if (activeSearch.options.includeTeamKeywords) niche.stats.keywordsTotalByTeamLabel += ' (included)';

  niche.stats = updateNicheDescription(niche);

  // console.log('niche.keywords: ' + niche.keywords);
  // console.log('niche.keywords.length: ' + niche.keywords.length);
  // console.log('niche.stats.keywords: ' + niche.stats.keywords);

  return niche.stats;
};

const updateNicheDescription = (niche: INicheDTO) => {
  const descriptionLength = 16;
  const descriptionLengthShort = 8;

  let description = '';
  let descriptionShort = description;

  let allKeywords = niche.keywords.concat(niche.prefixes).concat(niche.suffixes);

  allKeywords = uniq(allKeywords);

  if (allKeywords.length > 0) {
    description = '';
    for (const keyword of allKeywords) {
      description += keyword + ', ';
      if (description.length > descriptionLength) break;
    }
    for (const keyword of allKeywords) {
      descriptionShort += keyword + ', ';
      if (descriptionShort.length > descriptionLengthShort) break;
    }
  }

  description = description.charAt(0).toUpperCase() + description.slice(1);
  description = description.replace(/,\s*$/, '');
  if (allKeywords.length > 1) description += ' ...';

  descriptionShort = descriptionShort.charAt(0).toUpperCase() + descriptionShort.slice(1);
  descriptionShort = descriptionShort.replace(/,\s*$/, '');
  if (allKeywords.length > 1) descriptionShort += ' ..';

  if (allKeywords.length > 0) {
    description = '  ' + description + '';
    descriptionShort = '  ' + descriptionShort + '';
  }

  niche.stats.description = description;
  niche.stats.descriptionShort = descriptionShort;

  return niche.stats;
};

export const trackPageView = (path: string) => {
  ReactGA.send({ hitType: 'pageview', page: path /* , title: "Custom Title" */ });

  // ReactGA.pageview(path.toLowerCase());
  console.log('page', path);
};

/* export const trackModal = (path: string) => {
 trackEvent
  console.log('modal', path);
};
 */
export const trackEvent = (name: string, action?: string, value?: number) => {
  const gaEvent = {
    category: name.toLowerCase(),
    action: action ? action.toLowerCase() : '',
    value: value ? value : 0,
  };
  ReactGA.event(name, gaEvent);
  console.log('event', name, gaEvent);
};

export const trackSignup = (method: string) => {
  const gaEvent = {
    method: method.toLowerCase(),
  };
  ReactGA.event('signup', gaEvent);
  // trackAdConversion('signup', 0);
  console.log('signup', gaEvent);
};

export const trackLogin = (method: string) => {
  const gaEvent = {
    method: method.toLowerCase(),
  };
  ReactGA.event('login', gaEvent);
  console.log('login', gaEvent);
};

declare global {
  interface Window {
    gtag: any;
  }
}

function trackAdConversion(eventTarget: string, transaction_id: string, value: number) {
  const gtag = window.gtag;
  const values = {
    send_to: eventTarget,
    transaction_id,
    value,
    currency: 'USD',
  };
  if (gtag) {
    gtag('event', 'conversion', values);
    console.log('trackAdConversion: ', gtag);
  }
}

export const trackPurchase = (transaction_id: string, item_id: number, name: string, value: number) => {
  const details = {
    transaction_id, // avoid tracking duplicate conversions
    value,
    currency: 'USD',
    items: [
      {
        item_id,
        item_name: name,
      },
    ],
  };

  ReactGA.event('purchase', details);
  trackAdConversion('AW-1070724516/q6X3CJz2mwIQpOvH_gM', transaction_id, value); // Premium Purchase

  console.log('purchase', details);
};

export const trackError = (message: string, path?: string, details?: string) => {
  const values = {
    category: 'exception',
    message: message ? message : '',
    path: path ? path.toLowerCase() : '',
    details: details ? details : '',
  };

  // ReactGA.exception(fieldObject);

  // ReactGA.send({ hitType: 'exception', details });

  ReactGA.event('exception', values);

  // console.log('exception', values);
};

export const getContestUpgradeUrl = (project: IProjectDTO | null) => {
  // hooks error
  /*  const globalData = useSelector((state: IStoreState) => state.globalDataReducer);
  const activeUser = useSelector((state: IStoreState) => state.activeUserReducer);
  const activeProject = useSelector((state: IStoreState) => state.activeProjectReducer); */

  // const urls = globalData.loadedData.urls;

  // const response = urls.newContest;

  // if (!activeUser) return urls.newContestExternal;

  if (project && !project.isContest) return project.urls.editContest;
  return '/new-contest';
  // return response;
};

export const addAfter = (array: string | any[], index: any, newItems: any) => {
  if (!array) {
    return Array.isArray(newItems) ? newItems : [newItems];
  }
  if (typeof array === 'string') {
    return array.slice(0, index) + newItems + array.slice(index);
  }
  return [...array.slice(0, index), ...(Array.isArray(newItems) ? newItems : [newItems]), ...array.slice(index)];
};

export const getSearchUrl = (keywords: string, context: string, fallback: string) => {
  let redir = fallback;

  if (keywords !== '') keywords = keywords.replace(' and ', ' ');
  if (context !== '') context = context.replace(' and ', ' ');

  if (keywords === '' && context === '') {
    return redir;
  } else {
    if (fallback?.includes('?')) {
      redir += '&';
    } else {
      redir += '?';
    }
  }

  if (keywords === '') {
    keywords = context;
  }

  if (context === '') {
    context = keywords;
  }

  keywords = encodeURIComponent(keywords.toLowerCase());
  context = encodeURIComponent(context.toLowerCase());

  if (keywords.length < 50) {
    redir += 'keywords=' + keywords;
  }
  redir += '&context=' + context;

  // console.log(redir);

  return redir;
};

export const toTitleCase = (phrase: string) => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const isMobile = () => {
  // determines whether to auto close sidepanel
  const response = window.matchMedia('only screen and (max-width: 400px)').matches;
  return response;
};

export const hasThreeColumnsWidth = () => {
  // determines whether to auto close sidepanel
  const response = window.matchMedia('only screen and (min-width: 1280px)').matches;
  return response;
};

export const hasTwoColumnsWidth = () => {
  // determines whether to auto close sidepanel
  const response = window.matchMedia('only screen and (min-width: 1024px)').matches;
  return response;
};

export const isWideScreen = () => {
  // determines whether to auto show sidepanel
  // closes all full screen toolkit overlays when searching
  const response = window.matchMedia('only screen and (min-width: 1550px)').matches;
  return response;
};

export const renderDictionaryQueryTitle = (query: IDictionaryQueryDTO) => {
  let response = '';

  if (query.minLength !== 0 && query.maxLength !== 0 && query.minLength === query.maxLength) {
    response = query.minLength + '-letter ';
  } else if (query.minLength !== 0 && query.maxLength !== 0) {
    response = query.minLength + '-' + query.maxLength + ' letter ';
  } else if (query.minLength !== 0) {
    response = 'Min. ' + query.minLength + '-letter ';
  } else if (query.maxLength !== 0) {
    response = 'Max. ' + query.maxLength + '-letter ';
  }

  if (query.wordType === WordTypes.any) {
    response += response !== '' ? ' words' : 'Any dictionary words';
  } else {
    response += response !== '' ? WordTypes[query.wordType] + 's' : toSentenceCase(WordTypes[query.wordType] + 's');
  }

  let affixString = '';

  if (query.starts !== '') {
    affixString += ', starting with ' + toUpper(query.starts);
  }
  if (query.contains !== '') {
    affixString += ', containing ' + toUpper(query.contains);
  }
  if (query.ends !== '') {
    affixString += ', ending with ' + toUpper(query.ends);
  }

  affixString = affixString.trim();

  if (affixString.startsWith(',')) affixString = affixString.substring(1);

  response += ' ' + affixString;

  if (response === 'Any dictionary words') response = 'Create a list of dictionary words';

  // console.log(response);
  return response;

  // 1-letter nouns starting with "WE"
};

export const redirectToRegistration = (domainName: string, action?: string) => {
  let redirect = '';
  if (action && action === 'whois') {
    redirect =
      'https://www.anrdoezrs.net/click-1830209-11751890?sid=NSwhois&url=' +
      encodeURIComponent('https://www.godaddy.com/whois/results.aspx?isc=cjcbh99&checkAvail=1&tmskey=&domain=' + domainName);
    trackEvent('domain_whois', domainName ? domainName : undefined);
  } else if (action && action === 'purchase') {
    redirect = 'https://sedo.com/search/details/?partnerid=19768&domain=' + domainName;
    trackEvent('domain_purchase', domainName ? domainName : undefined);
  } else {
    redirect =
      'http://www.kqzyfj.com/click-1830209-10388358?url=https%3A%2F%2Fwww.godaddy.com%2Fdomains%2Fsearchresults.aspx%3FdomainToCheck%3D' +
      domainName +
      '%26checkAvail%3D1%26isc%3Dcjcdomsb2';

    // gtag('config', 'AW-1070724516');
    trackEvent('domain_registration', domainName ? domainName : undefined);
    trackAdConversion('AW-107072451', domainName ? domainName : '', 0);
  }

  if (domainName && domainName !== '') {
    //agent.Domains.register(domainName);
  }

  const newWin = window.open(redirect, '_blank');

  if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
    // POPUP BLOCKED
    window.location.replace(redirect);
  }
};

export const handleSignupRedirect = (formData: IOnboardingProjectDTO) => {
  if (formData) {
    localStorage.setItem('onboardingData', JSON.stringify(formData));
  }
  window.location.href = process.env.NEXT_PUBLIC_APP_URL + '/signup';
};

export function formatTitle(slug: string): string {
  if (!slug) return 'Name Collections';

  // Decode URL-encoded characters first
  slug = decodeURIComponent(slug);

  // Special cases for acronyms and specific terms
  const specialCases: { [key: string]: string } = {
    // Acronyms & Technical Terms
    ai: 'AI',
    ar: 'AR',
    vr: 'VR',
    ml: 'ML',
    it: 'IT',
    iot: 'IoT',
    nft: 'NFT',
    api: 'API',
    saas: 'SaaS',
    paas: 'PaaS',
    iaas: 'IaaS',
    b2b: 'B2B',
    b2c: 'B2C',
    c2c: 'C2C',
    p2p: 'P2P',
    cms: 'CMS',
    crm: 'CRM',
    erp: 'ERP',
    hr: 'HR',
    seo: 'SEO',
    smm: 'SMM',
    roi: 'ROI',
    ui: 'UI',
    ux: 'UX',
    sdk: 'SDK',
    sql: 'SQL',
    nosql: 'NoSQL',
    php: 'PHP',
    ios: 'iOS',
    diy: 'DIY',
    ngo: 'NGO',
    llc: 'LLC',
    ltd: 'Ltd',
    inc: 'Inc',
    ceo: 'CEO',
    cfo: 'CFO',
    cto: 'CTO',
    coo: 'COO',
    gdpr: 'GDPR',
    hipaa: 'HIPAA',
    pci: 'PCI',
    ssl: 'SSL',
    vpn: 'VPN',
    cdn: 'CDN',
    dns: 'DNS',
    url: 'URL',
    html: 'HTML',
    css: 'CSS',
    js: 'JS',
    spa: 'SPA',
    pwa: 'PWA',
    mvp: 'MVP',
    kpi: 'KPI',
    ecommerce: 'eCommerce',
    esports: 'eSports',
    emedia: 'eMedia',
    elearning: 'eLearning',
    usa: 'USA',
    uk: 'UK',
    eu: 'EU',

    // Articles, Conjunctions & Prepositions
    a: 'a',
    an: 'an',
    and: 'and',
    as: 'as',
    at: 'at',
    but: 'but',
    by: 'by',
    for: 'for',
    from: 'from',
    in: 'in',
    into: 'into',
    near: 'near',
    nor: 'nor',
    of: 'of',
    off: 'off',
    on: 'on',
    onto: 'onto',
    or: 'or',
    out: 'out',
    over: 'over',
    past: 'past',
    per: 'per',
    the: 'the',
    till: 'till',
    to: 'to',
    up: 'up',
    via: 'via',
    with: 'with',
    within: 'within',
  };

  const words = slug
    .replace(/-/g, ' ')
    .split(' ')
    .map((word, index) => {
      const lowerWord = word.toLowerCase();
      // Check if word is in special cases
      if (specialCases.hasOwnProperty(lowerWord)) {
        return specialCases[lowerWord];
      }
      // Always capitalize first word and words that aren't articles/prepositions
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

  return words.join(' ');
}
