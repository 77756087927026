'use client';
import * as React from 'react';
import { includes } from 'lodash';
import LayoutOverlay from 'namestation-ui/lib/Layouts/Overlay';
import Block from 'namestation-ui/lib/Components/Block';
import Overlay from 'namestation-ui/lib/Components/Overlay';
import { Overlays } from 'namestation-ui/lib/Providers/Overlays';
import router from 'next/router';
import OnboardingForm from '@/components/OnboardingForm';

export interface IOverlaySignupRequiredProps {
  layout?: 'default';
}

const OverlayKeywords: React.FunctionComponent<IOverlaySignupRequiredProps> = (props) => {
  const { openOverlays, overlaysActions } = React.useContext(Overlays);

  return (
    <Overlay
      isOpen={includes(openOverlays, 'keywords')}
      layout="focusview"
      title="Preferred Keywords"
      doClose={() => {
        overlaysActions({ action: 'close', name: 'all' });
        //  router.back();
      }}
      disableScrolling={true}
    >
      <LayoutOverlay
        title="Preferred Keywords"
        /*  description="Enter a list of keywords you'd like to be included in the names." */
        size="medium"
        buttonIcons={[
          {
            icon: 'close',
            onClick: () => {
              overlaysActions({ action: 'close', name: 'all' });
              //  router.back();
            },
            layout: 'icon-only',
          },
        ]}
        scrollableContent={true}
      >
        <React.Fragment>
          <OnboardingForm layout="keywords" />
        </React.Fragment>
      </LayoutOverlay>
    </Overlay>
  );
};

OverlayKeywords.displayName = 'OverlayKeywords';

export default OverlayKeywords;
