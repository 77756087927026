'use client';

import { isMobile } from 'common/customFunctions';
import { handleError } from 'common/functions';

import { IProjectDTO } from 'data/models/iProjectDTO';
import { IProjectLabelDTO } from 'data/models/iProjectLabelDTO';
import { IUserDTO } from 'data/models/iUserDTO';
import HeadingWithButtons from 'namestation-ui/lib/Components/HeadingWithButtons';
import LoadingContent from 'namestation-ui/lib/Components/LoadingContent';
import Link from 'next/link';
import * as React from 'react';
import { useSelector } from 'react-redux';
import agent from 'src/api/agent';
import { getGlobalData } from 'src/contexts/globalDataServer';

export interface ILatestWinnersProps {
  count: number;
  layout?: 'default' | 'sidebar';
  headingLevel?: 3 | 2;
  title?: string;
  keywords?: string;
  hideHeading?: boolean;
  project?: IProjectDTO | null;
  user?: IUserDTO | null;
  separated?: boolean;
  hasTitleSpacing?: boolean;
}

const LatestWinners: React.FunctionComponent<ILatestWinnersProps> = (props) => {
  const [winners, setWinners] = React.useState<IProjectLabelDTO[]>();
  const [title, setTitle] = React.useState<string>('Latest winners');
  const [globalData, setGlobalData] = React.useState<any>(null);

  React.useEffect(() => {
    const fetchGlobalData = async () => {
      const data = await getGlobalData();
      setGlobalData(data);
    };
    fetchGlobalData();
  }, []);

  React.useEffect(() => {
    if (!globalData) return;

    if (props.keywords) {
      agent.Projects.latestWinners(props.keywords, props.count)
        .then((response) => setWinners(response))
        .catch((e) => {
          setWinners(globalData.latestWinners.slice(0, props.count));
          handleError(e);
        });
    } else if (props.project) {
      agent.Projects.latestWinners(props.project.name, props.count)
        .then((response) => setWinners(response))
        .catch((e) => {
          setWinners(globalData.latestWinners.slice(0, props.count));
          handleError(e);
        });
    } else if (props.user) {
      agent.Users.contestsWon(props.user.id, props.count).then((response) => setWinners(response));
    } else setWinners(globalData.latestWinners.slice(0, props.count));
  }, [globalData, props.keywords, props.project, props.user, props.count]);

  React.useEffect(() => {
    if (props.title) setTitle(props.title);
  }, [props.title]);

  if (!globalData) return null;
  const urls = globalData.urls;

  if (!winners || winners.length === 0) {
    return null;
  }

  return (
    <React.Fragment>
      {props.separated && <hr />}
      {!props.hideHeading && (
        <HeadingWithButtons
          title={title}
          level={props.headingLevel}
          buttons={
            props.user || isMobile()
              ? undefined
              : [
                  {
                    title: globalData.stats.contestsAll + ' contests held',
                    intent: 'discrete',
                    size: 'small',

                    href: urls.contestsAll,
                    link: Link,
                    type: 'anchor',
                    layout: 'link',
                  },
                ]
          }
        />
      )}

      {winners ? (
        <ul
          className="list-winners-front"
          style={{ marginTop: props.hasTitleSpacing ? '24' : '0' }}
        >
          {winners.map((project, ai) => {
            return (
              <li key={ai}>
                <div className="list-winners-front__inner">
                  <div className="list-winners-front__title">
                    <Link href={project.url}>{project.name}</Link>
                  </div>
                  <ul className="list-winners-front__stats">
                    <li>{project.namesLabel} posted</li>
                    {/* <li>{project.stats.peopleLabel}</li> */}
                  </ul>
                  <div className="list-winners-front__domain">
                    <span className="list-winners-front__domain__label">Winner</span>
                    <span className="list-winners-front__domain__value">{project.winner}</span>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      ) : (
        <LoadingContent layout="inline" />
      )}
    </React.Fragment>
  );
};

LatestWinners.displayName = 'LatestWinners';

export default LatestWinners;
