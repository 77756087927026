'use client';
import * as React from 'react';

import Button from 'namestation-ui/lib/Components/Button';
import Buttons from 'namestation-ui/lib/Components/Buttons';
import ControlInput from 'namestation-ui/lib/Components/ControlInput';

import Well from 'namestation-ui/lib/Components/Well';
import Block from 'namestation-ui/lib/Components/Block';

import HeadingWithButtons from 'namestation-ui/lib/Components/HeadingWithButtons';

import ControlGroup from 'namestation-ui/lib/Components/ControlGroup';

import ButtonIcon from 'namestation-ui/lib/Components/ButtonIcon';
import { IOnboardingProjectDTO } from 'data/models/iOnboardingProjectDTO';

import { settings } from 'data/settings';
import { useRouter } from 'next/navigation';

import { getSlug } from 'common/customFunctions';
import { urls } from 'data/urls';
import LinkWrapper from '../Layout/link-wrapper';
import TopicSuggestions from '../NameGenerator/TopicSuggestions';
import { SearchTypes } from 'data/models/Enums/searchTypes';
import { Overlays } from 'namestation-ui/lib/Providers/Overlays';
import { OptionTypes } from 'data/models/Enums/optionTypes';
import { Fragment } from 'react';
import ControlTextarea from 'namestation-ui/lib/Components/ControlTextarea';
import FormRow from 'namestation-ui/lib/Components/FormRow';
import LayoutForm from 'namestation-ui/lib/Layouts/Form';
import { useDispatch, useSelector } from 'react-redux';
import { IStoreState } from '@/store/types';
import { setOnboardingProject } from '@/store/actions/onboardingFormActions';

export interface ISearchFormProps {
  layout?: 'default' | 'category' | 'keywords';
  initialTopic?: string;
  hasTopicField?: boolean;
  onGenerateMore?: () => void;
  handleFilter?: (filter: string) => void;
  searchWithRouter?: (phrase: string) => void;
  setActiveOption?: (option: OptionTypes) => void;
  isLoading?: boolean;
  hasFilters?: boolean;
}

const OnboardingForm: React.FunctionComponent<ISearchFormProps> = (props: ISearchFormProps) => {
  const dispatch = useDispatch();
  const savedOnboardingProject = useSelector((state: IStoreState) => state.onboardingFormReducer.onboardingProject);

  const defaultOnboardingProject = {
    topic: props.initialTopic || '',
    keywordCount: 0,
    preferredKeywords: '',
    preferredPrefixes: '',
    preferredSuffixes: '',
    activeOption: OptionTypes.none,
    slug: '',
    languageSlug: '',
  } as IOnboardingProjectDTO;

  const router = useRouter();
  const [isLoading, setIsLoading] = React.useState(false);
  const { overlaysActions } = React.useContext(Overlays);
  const [showPrefixesAndSuffixes, setShowPrefixesAndSuffixes] = React.useState(false);

  React.useEffect(() => {
    if (!savedOnboardingProject) {
      dispatch(setOnboardingProject(defaultOnboardingProject));
    }
  }, []);

  React.useEffect(() => {
    if (savedOnboardingProject && props.initialTopic) {
      dispatch(setOnboardingProject({ ...savedOnboardingProject, topic: props.initialTopic }));
    }
  }, [props.initialTopic]);

  React.useEffect(() => {
    if (!savedOnboardingProject) return;

    // Split by commas or spaces and filter out empty strings
    const keywordCount = savedOnboardingProject.preferredKeywords?.split(/[,\s]+/).filter((word) => word.length > 0).length || 0;
    const prefixCount = savedOnboardingProject.preferredPrefixes?.split(/[,\s]+/).filter((word) => word.length > 0).length || 0;
    const suffixCount = savedOnboardingProject.preferredSuffixes?.split(/[,\s]+/).filter((word) => word.length > 0).length || 0;

    const allWordsCount = keywordCount + prefixCount + suffixCount;

    if (savedOnboardingProject.keywordCount !== allWordsCount) {
      dispatch(setOnboardingProject({ ...savedOnboardingProject, keywordCount: allWordsCount }));
    }
  }, [savedOnboardingProject?.preferredKeywords, savedOnboardingProject?.preferredPrefixes, savedOnboardingProject?.preferredSuffixes]);

  const handleOnboardingSearch = () => {
    console.log('handleOnboardingSearch called, layout:', props.layout);

    if (props.layout === 'keywords') {
      console.log('Keywords layout detected');

      if (props.onGenerateMore) {
        console.log('Calling onGenerateMore');
        props.onGenerateMore();
      } else {
        console.log('onGenerateMore is not defined');
      }

      if (savedOnboardingProject) {
        dispatch(setOnboardingProject({ ...savedOnboardingProject, activeOption: OptionTypes.none }));
      }

      // overlaysActions({ action: 'close', name: 'all' });

      return;
    }

    if (props.layout === 'category') {
      props.onGenerateMore?.();
      return;
    }

    if (!savedOnboardingProject?.topic) {
      router.push(`/names`);
      return;
    }
    const slug = getSlug(savedOnboardingProject.topic);
    router.push(`/names/${slug}`);
  };

  const handleOnboardingTopicChange = (topic: string) => {
    if (savedOnboardingProject) {
      dispatch(setOnboardingProject({ ...savedOnboardingProject, topic }));
    }
  };

  const handleOnboardingTopicClick = (topic: string) => {
    handleOnboardingTopicChange(topic);
    const slug = topic
      .toLowerCase()
      .trim()
      .replace(/\s+/g, '-')
      .replace(/[^a-z0-9-]/g, '');

    router.push(`/names/${slug}`);
  };

  const renderTopicInput = () => {
    return (
      <React.Fragment>
        <HeadingWithButtons
          title={'What are you naming?'}
          level={4}
        />
        <div style={{ textAlign: 'left' }}>
          <ControlInput
            placeholder={settings.defaultPlaceholderTopic}
            value={savedOnboardingProject?.topic || ''}
            className="grow data-hj-allow"
            onChange={(e: { target: { value: string } }) => {
              handleOnboardingTopicChange(e.target.value);
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleOnboardingSearch();
              }
            }}
            aria-label="Enter what you want to name"
            id="topic-input"
          />

          <TopicSuggestions
            topic={savedOnboardingProject?.topic}
            searchType={SearchTypes.ai}
            onChange={(topic: string) => {
              handleOnboardingTopicClick(topic);
            }}
            initialContent={settings.exampleTopics}
            aria-label="Suggested topics"
          />
        </div>
      </React.Fragment>
    );
  };

  const renderWellContent = () => {
    return (
      <div
        style={{ textAlign: 'left' }}
        role="form"
        aria-label="Name generation form"
      >
        {renderTopicInput()}

        <Buttons layout="vertical-wide">
          <Button
            title="Generate Names"
            intent="primary"
            onClick={(e: React.SyntheticEvent) => {
              e.preventDefault();
              handleOnboardingSearch();
            }}
            aria-label="Generate names based on your topic"
          />
          <Button
            title="Random Names"
            layout="link"
            type="anchor"
            href={urls.randomNames}
            link={LinkWrapper}
            aria-label="Get random name suggestions"
          />
        </Buttons>
      </div>
    );
  };

  if (props.layout === 'category') {
    return (
      <React.Fragment>
        <ControlGroup>
          <ControlInput
            placeholder="E.g: bakery specializing in birthday cakes"
            className="grow data-hj-allow"
            maxLength={200}
            style={{ height: '40px' }}
            value={savedOnboardingProject?.topic || ''}
            disabled={savedOnboardingProject?.activeOption !== OptionTypes.none}
            addOn={
              <Button
                title=".COM"
                onClick={() => props.handleFilter && props.handleFilter('extensions')}
                intent="discrete"
                size="small"
                aria-label="Filter by .COM domains"
              />
            }
            onChange={(e: { target: { value: string } }) => handleOnboardingTopicChange(e.target.value)}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                props.searchWithRouter && savedOnboardingProject && props.searchWithRouter(savedOnboardingProject.topic);
              }
            }}
            aria-label="Enter your business description"
            id="category-topic-input"
          />

          <Button
            className="mobile-hide"
            title={'Generate Names'}
            onClick={() => props.searchWithRouter && savedOnboardingProject && props.searchWithRouter(savedOnboardingProject.topic)}
            loading={props.isLoading}
            intent="primary"
            aria-label="Generate names"
          />

          <ButtonIcon
            className="not-mobile-hide"
            icon="search"
            onClick={() => props.searchWithRouter && savedOnboardingProject && props.searchWithRouter(savedOnboardingProject.topic)}
            loading={props.isLoading}
            intent="primary"
            aria-label="Search for names"
          />
        </ControlGroup>
        {props.hasFilters ? (
          <Buttons
            size="small"
            aria-label="Filter options"
          >
            <Button
              title="Keywords"
              onClick={() => {
                if (!savedOnboardingProject) return;
                dispatch(
                  setOnboardingProject({
                    ...savedOnboardingProject,
                    activeOption: savedOnboardingProject?.activeOption === OptionTypes.focus ? OptionTypes.none : OptionTypes.focus,
                  }),
                );
              }}
              /*   intent={savedOnboardingProject?.keywordCount === 0 ? 'discrete' : 'default'} */
              intent={savedOnboardingProject?.activeOption === OptionTypes.focus ? 'default' : 'discrete'}
              size="small"
              aria-label="Filter by keywords"
              notifications={
                savedOnboardingProject?.keywordCount && savedOnboardingProject?.keywordCount > 0
                  ? savedOnboardingProject?.keywordCount
                  : undefined
              }
            />
            <Button
              title="Name Type"
              onClick={() => props.handleFilter && props.handleFilter('nametype')}
              intent="discrete"
              size="small"
              aria-label="Filter by name type"
            />
            {/*  <Button
            title="Theme"
            onClick={() => props.handleFilter && props.handleFilter('themes')}
            intent="discrete"
            size="small"
            aria-label="Filter by theme"
          /> */}

            <Button
              title="AI Names"
              onClick={() => props.handleFilter && props.handleFilter('ai')}
              intent="discrete"
              theme="primary"
              icon="star"
              size="small"
              aria-label="Create AI names"
            />
            <ButtonIcon
              icon="filter"
              onClick={() => props.handleFilter && props.handleFilter('options')}
              intent="discrete"
              size="small"
              aria-label="Filter by options"
            />
          </Buttons>
        ) : (
          <React.Fragment>
            <div className="mobile-hide">
              <TopicSuggestions initialContent={settings.exampleTopics} />
            </div>
            <div className="not-mobile-hide">
              <TopicSuggestions initialContent={settings.exampleTopics.slice(0, 3)} />
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  if (props.layout === 'keywords') {
    if (!savedOnboardingProject) return null;
    return (
      <Well>
        <h2>Enter all your preferred keywords</h2>
        <LayoutForm layout="wide">
          <FormRow label="Names should contain">
            <ControlInput
              style={{ textAlign: 'left' }}
              placeholder="Enter keyword(s) or any characters"
              onChange={(e: { target: { value: string } }) => {
                dispatch(setOnboardingProject({ ...savedOnboardingProject, preferredKeywords: e.target.value }));
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleOnboardingSearch();
                }
              }}
              className="small data-hj-allow grow"
              value={savedOnboardingProject.preferredKeywords || ''}
            />
          </FormRow>
          {/*   {showPrefixesAndSuffixes && ( */}
          <Fragment>
            <FormRow label="Names should start with">
              <ControlInput
                placeholder="Prefixes"
                style={{ textAlign: 'left' }}
                className="small data-hj-allow grow"
                onChange={(e: { target: { value: string } }) => {
                  dispatch(setOnboardingProject({ ...savedOnboardingProject, preferredPrefixes: e.target.value }));
                }}
                value={savedOnboardingProject.preferredPrefixes || ''}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleOnboardingSearch();
                  }
                }}
              />
            </FormRow>
            <FormRow label="Names should end with">
              <ControlInput
                placeholder="Suffixes"
                style={{ textAlign: 'left' }}
                className="small data-hj-allow grow"
                onChange={(e: { target: { value: string } }) => {
                  dispatch(setOnboardingProject({ ...savedOnboardingProject, preferredSuffixes: e.target.value }));
                }}
                value={savedOnboardingProject.preferredSuffixes || ''}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    handleOnboardingSearch();
                  }
                }}
              />
            </FormRow>
          </Fragment>
          {/*  )} */}
          <FormRow>
            <Buttons layout="vertical-wide">
              <Button
                title={'Generate Names'}
                type="button"
                intent="primary"
                loading={isLoading}
                onClick={(e) => {
                  e.preventDefault();

                  handleOnboardingSearch();
                }}
              />
              <Button
                title={'Save your keywords'}
                type="anchor"
                link={LinkWrapper}
                href={urls.signup}
                intent="default"
                layout="link"
              />
            </Buttons>
          </FormRow>
        </LayoutForm>
      </Well>
    );
  }

  return (
    <React.Fragment>
      <Block
        width="medium"
        className="mobile-hide"
      >
        <Well>{renderWellContent()}</Well>
      </Block>
      <div
        className="not-mobile-hide"
        role="main"
      >
        <Well>{renderWellContent()}</Well>
      </div>
    </React.Fragment>
  );
};

OnboardingForm.displayName = 'SearchFormAI';

export default OnboardingForm;
