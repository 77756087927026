'use client';
import * as React from 'react';
import { includes } from 'lodash';
import LayoutOverlay from 'namestation-ui/lib/Layouts/Overlay';
import Block from 'namestation-ui/lib/Components/Block';
import Overlay from 'namestation-ui/lib/Components/Overlay';
import { Overlays } from 'namestation-ui/lib/Providers/Overlays';
import router from 'next/router';
import OnboardingForm from '@/components/OnboardingForm';
import Well from 'namestation-ui/lib/Components/Well';
import Button from 'namestation-ui/lib/Components/Button';
import Buttons from 'namestation-ui/lib/Components/Buttons';
import LinkWrapper from '@/components/Layout/link-wrapper';
import { urls } from 'data/urls';

export interface IOverlaySignupRequiredProps {
  layout?: 'default';
}

const OverlayAI: React.FunctionComponent<IOverlaySignupRequiredProps> = (props) => {
  const { openOverlays, overlaysActions } = React.useContext(Overlays);

  return (
    <Overlay
      isOpen={includes(openOverlays, 'ai')}
      layout="focusview"
      title="AI Name Generator"
      doClose={() => {
        overlaysActions({ action: 'close', name: 'all' });
        //  router.back();
      }}
      disableScrolling={true}
    >
      <LayoutOverlay
        title="AI Name Generator"
        description="Create names for your brand that are truly unique"
        size="medium"
        buttonIcons={[
          {
            icon: 'close',
            onClick: () => {
              overlaysActions({ action: 'close', name: 'all' });
              //  router.back();
            },
            layout: 'icon-only',
          },
        ]}
        scrollableContent={true}
      >
        <React.Fragment>
          <hr className="mobile-hide" />
          <Block>
            <ul>
              <li>
                <strong>Intelligent Name Generator</strong> - Create names based on your topic, keywords, creative brief and preferences.
              </li>
              <li>
                <strong>Adaptive, Smarter Naming</strong> - Continuously improving names based on your feedback.
              </li>
              <li>
                <strong>Advanced Filtering</strong> - Filter and sort your top choices seamlessly as you narrow down the perfect domain
                name.
              </li>
            </ul>
            <p>Try our advanced tools today for names you won’t find anywhere else.</p>
            <Buttons layout="vertical">
              <Button
                title="Sign up for AI names"
                intent="primary"
                icon="star"
                type="anchor"
                link={LinkWrapper}
                href={urls.signup}
              />
              <Button
                title="Hold a Name Contest  "
                layout="link"
                intent="default"
                type="anchor"
                link={LinkWrapper}
                href={urls.contests}
                onClick={() => {
                  overlaysActions({ action: 'close', name: 'all' });
                }}
              />
            </Buttons>
          </Block>
        </React.Fragment>
      </LayoutOverlay>
    </Overlay>
  );
};

OverlayAI.displayName = 'OverlayAI';

export default OverlayAI;
