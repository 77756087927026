import { GlobalData } from 'data/models/Objects/globalData';
import { Category } from 'data/models/Objects/category';
import { cache } from 'react';
import agent from 'src/api/agent';

// Create a cached function to get the global data
export const getGlobalData = cache(async (): Promise<GlobalData> => {
  const response = await agent.Data.global();
  return response as GlobalData;
});

// Helper function to use in server components
export async function useServerGlobalData(): Promise<GlobalData> {
  return getGlobalData();
}

// Add new cached function for categories
export const getAllCategories = cache(async (): Promise<Category[]> => {
  const response = await agent.Data.categories();
  return response;
});

// Add helper function for server components
export async function useServerCategories(): Promise<Category[]> {
  return getAllCategories();
}
