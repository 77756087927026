import axios, { AxiosError, AxiosResponse } from 'axios';

import React from 'react';

import { AppData } from 'data/models/Objects/appData';
import { GlobalData } from 'data/models/Objects/globalData';
import { ContributorData } from 'data/models/Objects/contributorData';

import { IDomainCheckDTO } from 'data/models/iDomainCheckDTO';
import { IDomainCheckResponseDTO } from 'data/models/iDomainCheckResponseDTO';

import { IDomainDetailsDTO } from 'data/models/iDomainDetailsDTO';
import { IWordlistLabelDTO } from 'data/models/iWordlistLabelDTO';
import { IUserDTO } from 'data/models/iUserDTO';
import { IAuthenticationFormDTO } from 'data/models/iAuthenticationFormDTO';
import { IResetPasswordFormValuesDTO } from 'data/models/iResetPasswordFormValuesDTO';

import { ContributorInfo } from 'data/models/Objects/contributorInfo';

import { IProjectDTO } from 'data/models/iProjectDTO';
import { IProjectFormDTO } from 'data/models/iProjectFormDTO';
import { IProjectRequestDTO } from 'data/models/iProjectRequestDTO';
import { IProjectResponseDTO } from 'data/models/iProjectResponseDTO';
import { IProjectResponseWithDetailsDTO } from 'data/models/iProjectResponseWithDetailsDTO';

import { ProjectUrlsUpgrade } from 'data/models/Objects/projectUrlsUpgrade';

import { IEntriesRequestDTO } from 'data/models/iEntriesRequestDTO';
import { IEntriesResponseDTO } from 'data/models/iEntriesResponseDTO';
import { IEntryDTO } from 'data/models/iEntryDTO';
import { IEntryFormDTO } from 'data/models/iEntryFormDTO';

import { IMessagesRequestDTO } from 'data/models/iMessagesRequestDTO';
import { IMessagesResponseDTO } from 'data/models/iMessagesResponseDTO';
import { IMessageDTO } from 'data/models/iMessageDTO';
import { IMessageFormDTO } from 'data/models/iMessageFormDTO';

import { IActivitiesResponseDTO } from 'data/models/iActivitiesResponseDTO';
import { IActivitiesRequestDTO } from 'data/models/iActivitiesRequestDTO';

import { IDataResponseDTO } from 'data/models/iDataResponseDTO';

import { WordlistCategory } from 'data/models/Objects/wordlistCategory';
import { GenericResponse } from 'data/models/Objects/genericResponse';
import { NicheResponse } from 'data/models/Objects/nicheResponse';
import { Vote } from 'data/models/Objects/vote';
import { UserSettings } from 'data/models/Objects/userSettings';

import { ITagDTO } from 'data/models/iTagDTO';
import { ITagLabelDTO } from 'data/models/iTagLabelDTO';

import { IWordDTO } from 'data/models/iWordDTO';
import { IEntryCommentsResponseDTO } from 'data/models/iEntryCommentsResponseDTO';
import { IEntryCommentFormDTO } from 'data/models/iEntryCommentFormDTO';
import { IContributorResponseDTO } from 'data/models/iContributorResponseDTO';
import { IProjectLabelDTO } from 'data/models/iProjectLabelDTO';
import { IWordlistFormDTO } from 'data/models/iWordlistFormDTO';
import { IWordlistIntroDTO } from 'data/models/iWordlistIntroDTO';
import { IWordlistResponseDTO } from 'data/models/iWordlistResponseDTO';
import { IContributorDTO } from 'data/models/iContributorDTO';
/* import { IContributorFormDTO } from 'data/models/IContributorFormDTO';
import { IContributorApplicationDTO } from 'data/models/IContributorApplicationDTO'; */

import { IUserPasswordFormDTO } from 'data/models/iUserPasswordFormDTO';
import { IUserProfileFormDTO } from 'data/models/iUserProfileFormDTO';
import { IPointsDTO } from 'data/models/iPointsDTO';
import { IPayoutDTO } from 'data/models/iPayoutDTO';
import { IErrorLogDTO } from 'data/models/iErrorLogDTO';
import { IDictionaryQueryDTO } from 'data/models/iDictionaryQueryDTO';

import { Positions } from 'data/models/Enums/positions';
import { EmailTypes } from 'data/models/Enums/emailTypes';
import { LabelAndValue } from 'data/models/Objects/labelAndValue';
import { IWordAndRelatedDTO } from 'data/models/iWordAndRelatedDTO';
import { INicheDTO } from 'data/models/iNicheDTO';
import { IDomainNameDTO } from 'data/models/iDomainNameDTO';
import { IWordlistDTO } from 'data/models/iWordlistDTO';
import { IWordlistCategoryLabelDTO } from 'data/models/iWordlistCategoryLabelDTO';
import { IPlanDTO } from 'data/models/iPlanDTO';
import { IOrganizationDTO } from 'data/models/iOrganizationDTO';
import { PlanCycles } from 'data/models/Enums/planCycles';
import { NicheStats } from 'data/models/Objects/nicheStats';
import { NameTypes } from 'data/models/Enums/nameTypes';
import { SearchTermScopes } from 'data/models/Enums/searchTermScopes';
import { IResultsDTO } from 'data/models/iResultsDTO';
import { IVerificationResponseDTO } from 'data/models/iVerificationResponseDTO';
import { Category } from 'data/models/Objects/category';
import { IOnboardingProjectDTO } from 'data/models/iOnboardingProjectDTO';

/* import { AppToaster } from '../../../web/src/App'; */

const baseURL = process.env.NEXT_PUBLIC_API_URL;

console.log('API Base URL:', baseURL);

axios.defaults.baseURL = baseURL; // || 'https://apidev.namestation.com/';
axios.defaults.timeout = 60 * 1000;

const appVersion = 92; //must match the appVersion in API for auto refresh

const config = {
  headers: {
    appversion: appVersion,
  },
};

const responseBody = (response: AxiosResponse) => {
  if (!response) {
    console.log('responseBody.response is undefined');
    return;
  }
  return response.data;
};

axios.interceptors.request.use(
  (request) => {
    /* const token = localStorage.getItem('jwt');
    //  console.log('bearer token: ' + (token ? 'yes' : 'no'));
    if (token && request && request.headers) request.headers.Authorization = `Bearer ${token}`; */
    return request;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error),
);

let controller = new AbortController();

export const Abort = () => {
  if (controller) {
    console.log('Aborting', controller);
    controller.abort();
    controller = new AbortController();
  }
};

const successHandler = (response: any) => {
  // console.log('appVersion: ' + appVersion + ', api version ' + response.headers.appversion + ', local storage: ' + localStorage.getItem('appversion'));
  /*   if (response.headers && response.headers.appversion && Number(response.headers.appversion) !== appVersion) {
    if (!localStorage.getItem('appversion') || localStorage.getItem('appversion') !== response.headers.appversion) {
      console.log('updating to app version ' + response.headers.appversion);
      window.location.reload();
      localStorage.setItem('appversion', response.headers.appversion); //avoid infinite reloading
      return null;
    }
  } */
  if (response.data && response.data.messages && response.data.messages.hasAnyMessage && !response.data.messages.isShownInPage) {
    /*  AppToaster.addToast({
      intent: response.data.messages.hasErrorMessage ? 'danger' : 'success',

      dismissOnClick: true,

      timeout: response.data.messages.actionLabel
        ? 10000
        : response.data.messages.hasErrorMessage || response.data.messages.hasWarningMessage
          ? 4000
          : 2000,
      text: (
        <React.Fragment>
          {response.data.messages.errorMessage && <div>{response.data.messages.errorMessage}</div>}
          {response.data.messages.errorMessage2 && <div>{response.data.messages.errorMessage2}</div>}
          {response.data.messages.successMessage && <div>{response.data.messages.successMessage}</div>}
          {response.data.messages.successMessage2 && <div>{response.data.messages.successMessage2}</div>}
          {response.data.messages.warningMessage && <div>{response.data.messages.warningMessage}</div>}
          {response.data.messages.warningMessage2 && <div>{response.data.messages.warningMessage2}</div>}
           
        </React.Fragment>
      ),
    }); */
  }
  // console.log(response);
  return response;
};

const errorHandler = (error: any) => {
  let errorMessage = 'Server error';
  let errorMessage2 = 'Please reload the page or try again later';

  let status = 0;

  if (axios.isCancel(error)) {
    console.log('Request canceled', error.message);
    return;
  }

  console.error('Axios Error details:', error);

  if (error) {
    if (error && error.message === 'Network Error' && !error.response) {
      errorMessage = 'Network error - make sure API is running!';
      status = error?.response?.status;
      console.error('Network Error:', error);
    }
  }

  if (error.response) {
    status = error.response.status;
    console.error(`HTTP Error ${status}:`, error.response.data);
  }

  if (status === 400) {
    errorMessage = "Couldn't complete request";
    errorMessage2 = error.response.data;
    console.error('Bad Request:', error.response.data);
  } else if (status === 401) {
    console.error('Unauthorized request:', error.response.data);
    errorMessage = 'Unauthorized request';

    let isAuthentication = false;

    if (error.request && JSON.stringify(error.request).indexOf('users/me') > -1) isAuthentication = true;

    if (isAuthentication) {
      console.log('Authentication error detected');
      // window.location.replace('/login?message=expired');

      /*  AppToaster.addToast({
        intent: 'danger',
        timeout: 4000,
        text: (
          <React.Fragment>
            <div>{errorMessage}</div>
            {errorMessage2 && <div>{errorMessage2}</div>}
          </React.Fragment>
        ),
      }); */
    }
    /*  const token = localStorage.getItem('jwt');
    if (!token) {
      window.location.replace('/login?message=expired');
    }
    return Promise.reject(); */
    // window.location.replace('/login?message=expired');
  } else if (status === 404) {
    errorMessage = 'Requested resource not found';
    console.error('Not Found:', error.response.data);
  } else if (status === 500) {
    errorMessage = 'Server error';
    errorMessage2 = "We're sorry, but your request couldn't be completed.";
    console.error('Server Error:', error.response.data);
    /*  AppToaster.addToast({
      intent: 'danger',
      timeout: 3000,
      text: (
        <React.Fragment>
          <div>{errorMessage}</div>
          {errorMessage2 && <div>{errorMessage2}</div>}
        </React.Fragment>
      ),
    }); */

    //   window.location.replace('/error');
    //  return Promise.reject();
  } else {
    console.error('Unhandled error:', error);
    if (error.request && error.request.responseURL && error.request.responseURL.indexOf('/generate') !== -1) {
      console.error('Generate endpoint error:', error.response?.data);
      /*  AppToaster.addToast({
        intent: 'danger',
        timeout: 4000,

        text: (
          <React.Fragment>
            <div>Server error</div>
            <div>Please reload the page or try again later</div>
          </React.Fragment>
        ),
      }); */
    }
    console.error('Unhandled error: ' + error.message);
  }

  return Promise.reject({ ...error });
};

const requests = {
  get: (url: string) => axios.get(url, config).then(responseBody),
  post: (url: string, body: {}) => axios.post(url, body).then(responseBody),
  postAbortable: (url: string, body: {}) => {
    return axios.post(url, body, { signal: controller.signal }).then(responseBody);
  },
  put: (url: string, body: {}) => axios.put(url, body).then(responseBody),
  del: (url: string) => axios.delete(url).then(responseBody),
  postForm: (url: string, file: Blob) => {
    const formData = new FormData();
    formData.append('File', file);
    return axios
      .post(url, formData, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then(responseBody);
  },
};

const Data = {
  app: (): Promise<AppData> => requests.get(`/data/app`),
  global: (): Promise<GlobalData> => requests.get(`/data/global`),
  categories: (): Promise<Category[]> => requests.get(`/data/categories`),
  contributorData: (): Promise<ContributorData> => requests.get(`/data/contributorData`),
  logerror: (error: IErrorLogDTO): Promise<null> => requests.post(`/data/logError`, error),
  sitemap: (): Promise<string> => requests.get(`/data/sitemap`),
  query: (context: string, keywords: string): Promise<null> => requests.get(`/data/query?context=${context}&keywords=${keywords}`),
  topqueries: (): Promise<LabelAndValue[]> => requests.get(`/data/topqueries`),
  queries: (): Promise<INicheDTO[]> => requests.get(`/data/queries`),
  analysis: (domainDelimited: string, context: string): Promise<IDataResponseDTO> =>
    requests.get(`/data/analysis?DomainDelimited=${domainDelimited}&context=${context}`),
  alternatives: (domainDelimited: string, context: string): Promise<IDataResponseDTO> =>
    requests.get(`/data/alternatives?DomainDelimited=${domainDelimited}&context=${context}`),
  strategy: (projectID: number, keyword: string, nameType: NameTypes): Promise<IDataResponseDTO> =>
    requests.get(`/data/strategy?projectID=${projectID}&keyword=${keyword}&nameType=${nameType}`),
  guidance: (title: string): Promise<IDataResponseDTO> => requests.get(`/data/guidance?title=${title} `),

  keywords: (title: string): Promise<INicheDTO> => requests.get(`/data/keywords?title=${title} `),
  affixes: (title: string): Promise<IDataResponseDTO> => requests.get(`/data/affixes?title=${title} `),
  classifier: (title: string): Promise<IDataResponseDTO> => requests.get(`/data/classifier?title=${title} `),
  projectClassifier: (ProjectID: number): Promise<IDataResponseDTO> => requests.get(`/data/projectclassifier?ProjectID=${ProjectID} `),
  category: (topic: string): Promise<IDataResponseDTO> => requests.get(`/data/category?topic=${topic} `),
  topicSuggestions: (topic: string): Promise<IDataResponseDTO> => requests.get(`/data/topicSuggestions?topic=${topic} `),
};

const Domains = {
  generate: (domaincheck: IDomainCheckDTO, ProjectID: number): Promise<IDomainCheckResponseDTO> =>
    requests.postAbortable(`/domains/generate?ProjectID=${ProjectID}`, domaincheck),
  generateMore: (domaincheck: IDomainCheckDTO, ProjectID: number): Promise<IDomainNameDTO[]> =>
    requests.postAbortable(`/domains/generateMore?ProjectID=${ProjectID}`, domaincheck),
  generateExternal: (onboardingProject: IOnboardingProjectDTO): Promise<IDomainNameDTO[]> =>
    requests.postAbortable(`/domains/generateexternal`, onboardingProject),
  verify: (domainNames: IDomainNameDTO[]): Promise<IVerificationResponseDTO> => requests.postAbortable(`/domains/verify`, domainNames),
  preload: (domaincheck: IDomainCheckDTO, ProjectID: number, pageNumber: number): Promise<IDomainCheckResponseDTO> =>
    requests.post(`/domains/preload?ProjectID=${ProjectID}&pageNumber=${pageNumber}`, domaincheck),
  details: (domainDelimited: string): Promise<IDomainDetailsDTO> =>
    requests.get(`/domains/details?DomainDelimited=${domainDelimited}&pageNumber=1`),

  recommended: (currentSearch: IDomainCheckDTO): Promise<IDomainCheckDTO[]> => requests.post(`/domains/recommended`, currentSearch),
  register: (domainname: string): Promise<null> => requests.get(`/domains/register?domainname=${domainname}`),
  searchMethods: (currentSearch: IDomainCheckDTO): Promise<IDomainCheckDTO[]> => requests.post(`/domains/searchmethods`, currentSearch),
  // searchContexts: (currentSearch: IDomainCheckDTO): Promise<ContextObject[]> => requests.post(`/domains/searchcontexts`, currentSearch),
};

const Users = {
  isAuthenticated: (): Promise<boolean> => requests.get(`/users/isAuthenticated`),
  user: (slug: string): Promise<IUserDTO> => requests.get(`/users/user?slug=${slug}`),

  loginOrSignUp: (formValues: IAuthenticationFormDTO): Promise<GenericResponse> => {
    return requests.post(`/users/loginorsignup`, formValues);
  },
  /*   loginSocial: (code: string, provider: string, isMobile: boolean): Promise<GenericResponse> => requests.get(`/users/loginSocial?code=${code}&provider=${provider}&isMobile=${isMobile}`), */
  forgotpassword: (email: string): Promise<GenericResponse> => requests.get(`/users/forgotpassword?email=${email}`),
  verifytoken: (token?: string): Promise<GenericResponse> => requests.get(`/users/verifytoken?token=${token}`),
  resetpassword: (formValues: IResetPasswordFormValuesDTO): Promise<GenericResponse> => requests.post(`/users/resetpassword`, formValues),
  unsubscribe: (token: string): Promise<GenericResponse> => requests.get(`/users/unsubscribe?token=${token}`),

  /*  saveonboarding: (onboardingNiche: INicheDTO, isSignupRequest: boolean): Promise<IUserDTO> => requests.post(`/users/saveonboarding?isSignupRequest=${isSignupRequest}`, onboardingNiche), */
  me: (isFirstRequestAfterLogin: boolean): Promise<IUserDTO> =>
    requests.get(`/users/me?isFirstRequestAfterLogin=${isFirstRequestAfterLogin}`),
  subscription: (): Promise<IUserDTO> => requests.get('/users/subscription'),
  cancelsubscription: (): Promise<IUserDTO> => requests.get('/users/cancelsubscription'),
  userid: (): Promise<number> => requests.get('/users/userid'),
  emailByUserID: (uid: number): Promise<string> => requests.get('/users/emailbyuserid?uid=${uid}'),

  // subscription: (): Promise<ISubscriptionPlanDTO> => requests.get('/users/subscription'),

  profile: (): Promise<IUserProfileFormDTO> => requests.get(`/users/profile`),
  saveProfile: (formValues: IUserProfileFormDTO): Promise<GenericResponse> => requests.post(`/users/profile`, formValues),
  savePhoto: (photo: Blob): Promise<GenericResponse> => requests.postForm(`/users/savePhoto`, photo),
  deletePhoto: (): Promise<GenericResponse> => requests.get(`/users/deletePhoto`),
  savePassword: (formValues: IUserPasswordFormDTO): Promise<GenericResponse> => requests.post(`/users/password`, formValues),

  savedSearches: (): Promise<IDomainCheckDTO[]> => requests.get(`/users/savedsearches`),
  points: (): Promise<IPointsDTO[]> => requests.get(`/users/points`),
  payouts: (): Promise<IPayoutDTO[]> => requests.get(`/users/payouts`),
  plans: (isDiscount?: boolean): Promise<IPlanDTO[]> => requests.get(`/users/plans?isDiscount=${isDiscount}`),
  planspublic: (): Promise<IPlanDTO[]> => requests.get(`/users/planspublic`),
  plan: (cycle: PlanCycles): Promise<IPlanDTO> => requests.get(`/users/plan?cycle=${cycle}`),

  newusers: (searchKeyword: string, filterPaid: boolean, filterContributors: boolean): Promise<IUserDTO[]> =>
    requests.get(`/users/newusers?searchKeyword=${searchKeyword}&filterPaid=${filterPaid}&filterContributors=${filterContributors}`),
  contestsWon: (id: number, count: number): Promise<IProjectLabelDTO[]> => requests.get(`/users/contestswon?id=${id}&count=${count}`),
  contestsHeld: (id: number): Promise<IProjectResponseDTO> => requests.get(`/users/contestsheld?id=${id}`),

  customWordlists: (id: number): Promise<IWordlistIntroDTO[]> => requests.get(`/users/customwordlists?id=${id}`),
  customWordlist: (wordlistID: string): Promise<IWordlistFormDTO> => requests.get(`/users/customwordlist?wordlistID=${wordlistID}`),
  saveCustomWordlist: (formValues: IWordlistFormDTO): Promise<IWordlistResponseDTO> =>
    requests.post(`/users/savecustomwordlist`, formValues),
  //deleteCustomWordlist: (id: string): Promise<Messages> => requests.get(`/users/deletecustomwordlist?id=${id}`),
  customWordlistsCategory: (): Promise<WordlistCategory> => requests.get(`/users/customWordlistsCategory`),

  // settings
  saveSettings: (settings: UserSettings): Promise<null> => requests.post(`/users/savesettings`, settings),
  expandedmenu: (isExpanded: boolean): Promise<null> => requests.get(`/users/expandedmenu?isExpanded=${isExpanded}`),
  searchassistant: (isVisible: boolean): Promise<null> => requests.get(`/users/searchassistant?isVisible=${isVisible}`),
  pagesize: (pagesize: number): Promise<null> => requests.get(`/users/pagesize?pagesize=${pagesize}`),
  columns: (columns: number): Promise<null> => requests.get(`/users/columns?columns=${columns}`),
  checkavailability: (checkavailability: boolean): Promise<null> =>
    requests.get(`/users/checkavailability?checkavailability=${checkavailability}`),
  hidereviewed: (hidereviewed: boolean): Promise<null> => requests.get(`/users/hidereviewed?hidereviewed=${hidereviewed}`),
  notifications: (notifications: boolean): Promise<GenericResponse> => requests.get(`/users/notifications?notifications=${notifications}`),
  savewords: (savewords: boolean): Promise<null> => requests.get(`/users/savewords?savewords=${savewords}`),
  savecurrentprojectid: (id: number): Promise<null> => requests.get(`/users/savecurrentprojectid?id=${id}`),

  //contributor
  contributorInfo: (): Promise<ContributorInfo> => requests.get(`/users/contributorInfo`),
  contributor: (id: number): Promise<IContributorDTO> => requests.get(`/users/contributor?id=${id}`),
  /*   topcontributors: (): Promise<IContributorDTO[]> => requests.get(`/users/topcontributors?static=true`), */
  contributors: (): Promise<IContributorDTO[]> => requests.get(`/users/contributors`),
  //saveContributorApplication: (form: IContributorFormDTO): Promise<GenericResponse> =>
  //  requests.post(`/users/saveContributorApplication`, form),
  // contributorApplications: (): Promise<IContributorApplicationDTO[]> => requests.get(`/users/contributorApplications`),
  setContributorStatus: (userUID: number, status: boolean): Promise<GenericResponse> =>
    requests.get(`/users/setContributorStatus?userUID=${userUID}&status=${status}`),
};

const Niche = {
  topic: (topic: string): Promise<INicheDTO> => requests.get(`/niche/topic?topic=${topic}`),
  topicCount: (topic: string): Promise<number> => requests.get(`/niche/topicCount?topic=${topic}`),
  existsTopicWithKeywords: (topic: string): Promise<boolean> => requests.get(`/niche/existsTopicWithKeywords?topic=${topic}`),
  /*   category: (id: number): Promise<INicheDTO> => requests.get(`/niche/category?id=${id}`),
  categories: (): Promise<INicheDTO[]> => requests.get(`/niche/categories`), */
  // saveNiche: (niche: INicheDTO): Promise<INicheDTO> => requests.post(`/niche/saveniche`, niche),
  deleteNiche: (id: number): Promise<GenericResponse> => requests.get(`/niche/deleteniche?id=${id}`),
  createTagNiche: (tagID: number): Promise<INicheDTO> => requests.get(`/niche/createTagNiche?tagid=${tagID}`),
  projectkeywords: (projectID: number): Promise<INicheDTO> => requests.get(`/niche/projectkeywords?projectID=${projectID}`),
  topickeywords: (ID: number): Promise<INicheDTO> => requests.get(`/niche/topickeywords?ID=${ID}`),
  saveKeywords: (niche: INicheDTO): Promise<string> => requests.post(`/niche/saveKeywords`, niche),
  saveWordlists: (niche: INicheDTO): Promise<NicheResponse> => requests.post(`/niche/saveWordlists`, niche),

  wordlistCategories: (
    ProjectID: number,
    CategoryID: number,
    excludeSelected: boolean,
    includeProjectLists: boolean,
  ): Promise<IWordlistCategoryLabelDTO[]> =>
    requests.get(
      `/niche/wordlistCategories?ProjectID=${ProjectID}&CategoryID=${CategoryID}&includeProjectLists=${includeProjectLists}&excludeSelected=${excludeSelected}`,
    ),
  wordlists: (ProjectID: number, CategoryID: number, includeProjectLists: boolean): Promise<IWordlistLabelDTO[]> =>
    requests.get(`/niche/wordlists?ProjectID=${ProjectID}&CategoryID=${CategoryID}&includeProjectLists=${includeProjectLists} `),
  addWordlist: (wordlistLabel: IWordlistLabelDTO): Promise<NicheResponse> => requests.post(`/niche/addWordlist`, wordlistLabel),
  removeWordlist: (wordlistLabel: IWordlistLabelDTO): Promise<NicheResponse> => requests.post(`/niche/removeWordlist`, wordlistLabel),
  clearWordlists: (ProjectID: number, CategoryID: number): Promise<NicheResponse> =>
    requests.get(`/niche/clearWordlists?ProjectID=${ProjectID}&CategoryID=${CategoryID}`),

  recommendedwordlists: (keywords: string): Promise<IWordlistLabelDTO[]> =>
    requests.get(`/niche/recommendedwordlists?keywords=${keywords}`),
  recommendedniche: (keywords: string): Promise<INicheDTO> => requests.get(`/niche/recommendedniche?keywords=${keywords}`),
  recommendednicheforproject: (onboardingProject: INicheDTO): Promise<INicheDTO> =>
    requests.post(`/niche/recommendednicheforproject`, onboardingProject),

  saveProjectKeyword: (id: number, word: string, position: Positions): Promise<NicheResponse> =>
    requests.get(`/niche/saveProjectKeyword?id=${id}&word=${word}&position=${position}`),
  deleteProjectKeyword: (id: number, word: string, position: Positions): Promise<NicheResponse> =>
    requests.get(`/niche/deleteProjectKeyword?id=${id}&word=${word}&position=${position}`),
  stats: (ProjectID: number): Promise<NicheStats> => requests.get(`/niche/stats?ProjectID=${ProjectID}`),
};

const Projects = {
  projects: (request: IProjectRequestDTO): Promise<IProjectResponseDTO> => requests.post(`/projects/projects`, request),
  projectsWithDetails: (request: IProjectRequestDTO): Promise<IProjectResponseWithDetailsDTO> =>
    requests.post(`/projects/projectswithdetails`, request),
  project: (id: number): Promise<IProjectDTO> => requests.get(`/projects/project?id=${id}`),
  urlsUpgrade: (id: number): Promise<ProjectUrlsUpgrade> => requests.get(`/projects/urlsUpgrade?id=${id}`),
  form: (id: string): Promise<IProjectFormDTO> => requests.get(`/projects/form?id=${id}`),
  save: (projectForm: IProjectFormDTO): Promise<IProjectDTO> => requests.post(`/projects/save`, projectForm),
  /*   create: (onboardingNiche: INicheDTO): Promise<IProjectDTO> => requests.post(`/projects/create`, onboardingNiche), */
  delete: (id: string): Promise<GenericResponse> => requests.get(`/projects/delete?id=${id}`),
  close: (id: string): Promise<GenericResponse> => requests.get(`/projects/close?id=${id}`),
  reopen: (id: string): Promise<GenericResponse> => requests.get(`/projects/reopen?id=${id}`),
  selectWinner: (id: string, entryId: number): Promise<GenericResponse> =>
    requests.get(`/projects/selectWinner?id=${id}&entryid=${entryId}`),
  recent: (InludeAllAvailable: boolean, count: number): Promise<IProjectResponseDTO> =>
    requests.get(`/projects/recent?InludeAllAvailable=${InludeAllAvailable}&count=${count}`),
  latestContests: (): Promise<IProjectLabelDTO[]> => requests.get(`/projects/latestcontests`),
  latestWinners: (keywords: string, count: number): Promise<IProjectLabelDTO[]> =>
    requests.get(`/projects/latestwinners?keywords=${keywords}&count=${count}`),
  allwinners: (keywords: string, count: number): Promise<IProjectDTO[]> =>
    requests.get(`/projects/allwinners?keywords=${keywords}&count=${count}`),
  myContests: (): Promise<IProjectLabelDTO[]> => requests.get(`/projects/mycontests`),

  recentwords: (id: number): Promise<string[]> => requests.get(`/projects/recentwords?id=${id}`),

  people: (id: number): Promise<IContributorResponseDTO> => requests.get(`/projects/people?id=${id}`),
  points: (id: number): Promise<IEntriesResponseDTO> => requests.get(`/projects/points?id=${id}`),

  searchhistory: (id: number): Promise<IDomainCheckDTO[]> => requests.get(`/projects/searchhistory?id=${id}`),
  clearsearchhistory: (id: number): Promise<null> => requests.get(`/projects/clearsearchhistory?id=${id}`),

  verify: (id: string, state: string): Promise<IProjectDTO> => requests.get(`/projects/verify?id=${id}&state=${state}`),
  fixpoints: (id: number): Promise<GenericResponse> => requests.get(`/projects/fixpoints?id=${id}`),
  createonboardingproject: (userID: number, niche: INicheDTO): Promise<null> =>
    requests.post(`/projects/createonboardingproject?userID=${userID}`, niche),

  niche: (projectID: number): Promise<INicheDTO> => requests.get(`/projects/niche?projectID=${projectID}`),
  projectNiches: (projectID: number): Promise<INicheDTO[]> => requests.get(`/projects/projectNiches?projectID=${projectID}`),
  entriesCount: (projectID: number): Promise<number> => requests.get(`/projects/entriesCount?projectID=${projectID}`),
  /* prompts: (projectUID: number, userUID: number): Promise<IPromptDTO[]> =>
    requests.get(`/projects/prompts?projectUID=${projectUID}&userUID=${userUID}`),
  deleteprompts: (projectUID: number, userUID: number): Promise<null> =>
    requests.get(`/projects/deleteprompts?projectUID=${projectUID}&userUID=${userUID}`),
  deleteprompt: (promptUID: number): Promise<null> => requests.get(`/projects/deleteprompt?promptUID=${promptUID}`),
  latestprompt: (projectUID: number, userUID: number): Promise<IPromptDTO> =>
    requests.get(`/projects/latestprompt?projectUID=${projectUID}&userUID=${userUID}`), */
};

const Entries = {
  entries: (request: IEntriesRequestDTO): Promise<IEntriesResponseDTO> => requests.post(`/entries/entries`, request),
  publicEntries: (projectID: number): Promise<string[]> => requests.get(`/entries/publicentries?projectID=${projectID}`),
  entry: (id: string): Promise<IEntryDTO> => requests.get(`/entries/entry?id=${id}`),
  save: (entry: IEntryFormDTO): Promise<IEntryDTO> => requests.post(`/entries/save`, entry),
  delete: (id: number): Promise<GenericResponse> => requests.get(`/entries/delete?id=${id}`),
  withdraw: (id: number): Promise<GenericResponse> => requests.get(`/entries/withdraw?id=${id}`),
  saveShortlisted: (id: number, isShortlisted: boolean): Promise<GenericResponse> =>
    requests.get(`/entries/saveShortlisted?id=${id}&isShortlisted=${isShortlisted}`),
  saveVote: (vote: Vote): Promise<GenericResponse> => requests.post(`/entries/savevote`, vote),
  saveComment: (comment: IEntryCommentFormDTO): Promise<IEntryCommentsResponseDTO> => requests.post(`/entries/savecomment`, comment),
  deleteComment: (id: number, entryId: number): Promise<IEntryCommentsResponseDTO> =>
    requests.get(`/entries/deletecomment?id=${id}&entryId=${entryId}`),
};

const Messages = {
  messages: (request: IMessagesRequestDTO): Promise<IMessagesResponseDTO> => requests.post(`/messages/messages`, request),
  message: (id: string): Promise<IMessageDTO> => requests.get(`/messages/message?id=${id}`),
  save: (message: IMessageFormDTO): Promise<GenericResponse> => requests.post(`/messages/save`, message),
  delete: (id: string): Promise<GenericResponse> => requests.get(`/messages/delete?id=${id}`),
};

const Activities = {
  activities: (request: IActivitiesRequestDTO): Promise<IActivitiesResponseDTO> => requests.post(`/activities/activities`, request),
  hasnotification: (): Promise<boolean> => requests.get(`/activities/hasnotification`),
};

const Words = {
  word: (keyword: string): Promise<IWordDTO> => requests.get(`/words/word?keyword=${keyword}`),
  related: (keyword: string): Promise<string[]> => requests.get(`/words/related?keyword=${keyword}`),
  suggestions: (query: string, count: number, includeOriginal: boolean): Promise<string[]> =>
    requests.get(`/words/suggestions?query=${query}&count=${count}&includeOriginal=${includeOriginal}`),
  suggestionsForTopic: (topic: string, count: number): Promise<string[]> =>
    requests.get(`/words/suggestionsForTopic?topic=${topic}&count=${count}`),
  suggestionsForProject: (ProjectID: number, count: number, scope: SearchTermScopes): Promise<string[]> =>
    requests.get(`/words/suggestionsforproject?ProjectID=${ProjectID}&count=${count}&scope=${scope}`),
  containing: (query: string, ProjectID: number): Promise<IWordlistLabelDTO[]> =>
    requests.get(`/words/containing?query=${query}&ProjectID=${ProjectID}`),
  keywordsuggestions: (niche: INicheDTO, count: number): Promise<string[]> =>
    requests.post(`/words/keywordsuggestions?count=${count}`, niche),
  wordlistsuggestions: (niche: INicheDTO): Promise<IWordlistLabelDTO[]> => requests.post(`/words/wordlistsuggestions`, niche),
  addexactwordlists: (niche: INicheDTO): Promise<INicheDTO> => requests.post(`/words/addexactwordlists`, niche),
  exactwordlists: (keywords: string): Promise<IWordlistLabelDTO[]> => requests.get(`/words/exactwordlists?keywords=${keywords}`),
  wordlist: (id: string): Promise<IWordlistDTO> => requests.get(`/words/wordlist?id=${id}`),
  dictionarycount: (dictionaryQuery: IDictionaryQueryDTO): Promise<number> => requests.post(`/words/dictionarycount`, dictionaryQuery),
  topAndRelated: (): Promise<IWordAndRelatedDTO[]> => requests.get(`/words/topAndRelated`),
  top: (): Promise<string[]> => requests.get(`/words/top`),
};

const Tags = {
  tag: (slug: string, saveview: boolean): Promise<ITagDTO> => requests.get(`/tags/tag?slug=${slug}&saveview=${saveview}`),
  tagNames: (
    slug: string,
    count: number,
    type: string,
    page: number,
    getDomainNames: boolean,
    languageSlug?: string,
  ): Promise<IDomainNameDTO[]> =>
    requests.get(
      `/tags/tagnames?slug=${slug}&count=${count}&type=${type}&page=${page}&getDomainNames=${getDomainNames}&languageSlug=${languageSlug}`,
    ),

  tagContests: (slug: string, count: number): Promise<IProjectLabelDTO[]> => requests.get(`/tags/tagcontests?slug=${slug}&count=${count}`),
  tagById: (id: number): Promise<ITagDTO> => requests.get(`/tags/tagById?id=${id}`),
  delete: (id: number): Promise<null> => requests.get(`/tags/delete?id=${id}`),
  important: (id: number, isImportant: boolean): Promise<null> => requests.get(`/tags/important?id=${id}&isImportant=${isImportant}`),
  featured: (id: number, isFeatured: boolean): Promise<null> => requests.get(`/tags/featured?id=${id}&isFeatured=${isFeatured}`),
  tags: (): Promise<ITagLabelDTO[]> => requests.get(`/tags/tags`),
  tagsAll: (isPopular: boolean, isImportant: boolean, isFeatured: boolean): Promise<ITagLabelDTO[]> =>
    requests.get(`/tags/tagsall?isPopular=${isPopular}&isImportant=${isImportant}&isFeatured=${isFeatured}`),
  autocomplete: (keywords: string): Promise<string[]> => requests.get(`/tags/autocomplete?keywords=${keywords}`),
};

const Organizations = {
  organizations: (request: IProjectRequestDTO): Promise<IProjectResponseDTO> => requests.post(`/projects/projects`, request),
  organization: (id: string): Promise<IOrganizationDTO> => requests.get(`/organizations/organization?id=${id}`),

  save: (projectForm: IProjectFormDTO): Promise<IProjectDTO> => requests.post(`/projects/save`, projectForm),
  create: (onboardingNiche: INicheDTO): Promise<IProjectDTO> => requests.post(`/projects/create`, onboardingNiche),
  delete: (id: string): Promise<GenericResponse> => requests.get(`/projects/delete?id=${id}`),
};

const System = {
  sendtestemail: (emailType: EmailTypes): Promise<GenericResponse> => requests.get(`/system/sendtestemail?emailType=${emailType}`),
  resetcache: (): Promise<GenericResponse> => requests.get(`/system/resetcache`),
  cache: (): Promise<LabelAndValue[]> => requests.get(`/system/cache`),
  automation: (): Promise<string> => requests.get(`/system/automation`),
  defaultaction: (): Promise<GenericResponse> => requests.get(`/system/defaultaction`),
  error: (code: number): Promise<string> => requests.get(`/system/error?code=${code}`),
};

export default {
  Projects,
  Niche,
  Users,
  Domains,
  Data,
  Entries,
  Messages,
  Activities,
  Words,
  Tags,
  System,
  Organizations,
  Abort,
  appVersion,
};
